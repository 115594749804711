/* .portfolio h2 {
    margin-bottom: 4rem;
    font-size: 3rem;
  }
  
  .portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2.5rem;
  }
  
  .portfolio-container .portfolio-box {
    position: relative;
    border-radius: 2rem;
    box-shadow: 0 0 1rem var(--main-color);
    overflow: hidden;
    display: flex;
  }
  
  .portfolio-box img {
    width: 100%;
    transition: .5s ease;
  }
  
  .portfolio-box:hover img {
    transform: scale(1.3);
  }
  
  .portfolio-box .portfolio-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,.1),var(--main-color));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem;
    transform: translateY(100%);
    transition: .5s ease;
  }
  
  .portfolio-box:hover .portfolio-layer {
    transform: translateY(0);
  }
  
  .portfolio-layer h4 {
    font-size: 2rem;
  }
  
  .portfolio-layer p {
    font-size: 1rem;
    margin: .3rem 0 1rem;
  }
  
  .portfolio-layer a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background: var(--text-color);
    border-radius: 50%;
  }
  
  .portfolio-layer a i {
    font-size: 2rem;
    color: var(--second-bg-color);
  }
   */

   /* App.css */
   /* Home Section */

   /* About Section Styling */
/* .about {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    /* background-color: #f4f4f4; /* Light grey background for contrast */
  /* } */
  
 
   
  

  
  
  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

:root {
    /* --bg-color: #1f242d; */
    --bg-color: #f3f3f3;
    --second-bg-color: #2a69dd;
    --text-color: #030303;
    --main-color: #0ef;
    /* --main-color: #502ad9; */
    /* --text-color: black; */
}

html {
    overflow-x: hidden;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

section {
    min-height: 100vh;
    padding: 10rem 9% 2rem;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.logo {
    font-size: 2.5rem;
    color: var(--text-color);
    font-weight: 600;
    cursor: pointer;
}

.header .bx {
    display: none;
}

.navbar a {
    font-size: 1.3rem;
    color: var(--text-color);
    margin-left: 4rem;
    transition: .3s;
}

.navbar a:hover {
    color: var(--main-color);
}

#menu-icon {
    font-size: 3.6rem;
    color: var(--text-color);
}

.home {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;

   
    
}

.home-img img {
    width: 25vw;
}

.home-content h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 20px;
    /* color : #f04; */
}

.home-content h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    margin-top: 20px;
    /* color: var(--main-color); */
    color : #f04;
}

span {
    /* color: var(--main-color); */
    /* color: #000000; */
    color : #f04;
}

.home-content p {
    font-size: 1.6rem;
    margin-top: 20px;
}

.social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    /* background: transparent; */
    /* border: .2rem solid var(--main-color); */
    border: .2rem solid black;
    border-radius: 50%;
    font-size: 2rem;
    /* color: var(--main-color); */
    color : black;
    margin-top: 20px;
    padding: 20px;
    transition: .5s ease;
    /* color : rgba(165, 4, 152, 0.595); */
}

.social-media a:hover {
    background: var(--main-color);
    color: var(--second-bg-color);
    box-shadow: 0 0 1rem var(--main-color);
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    background: var(--second-bg-color);
}

.about-img img {
    width: 25vw;
    border: 5px solid #ff9900;
}

.heading {
    text-align: center;
    font-size: 4.5rem;
}

.about-content h2 {
   
    text-align: left;
    line-height: 1.2;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    margin-top: 20px;
}

.about-content h3 {
    font-size: 2.6rem;
    font-weight: 700;
    margin-top: 20px;
    
}

.about-content p {
   
    /* font-size: 1.6rem;
    margin-top: 20px; */
    color: #000000;
  font-size: 1.6rem; /* Font size as requested */
  line-height: 1.8rem; /* Increased line height for better readability */
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: justify; /* Align text to both left and right edges for a cleaner look */
}

.btn {
    display: inline-block;
    padding: 1rem 2.8rem;
    background: var(--main-color);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--main-color);
    margin-top: 4vh;
}

.portfolio h2 {
    margin-bottom: 4rem;
    font-size: 3rem;
}

.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2.5rem;
}

.portfolio-container .portfolio-box {
    position: relative;
    border-radius: 2rem;
    box-shadow: 0 0 1rem var(--main-color);
    overflow: hidden;
    display: flex;
}

.portfolio-box img {
    width: 100%;
    transition: .5s ease;
}

.portfolio-box:hover img {
    transform: scale(1.3);
}

.portfolio-box .portfolio-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, .1), var(--main-color));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem;
    transform: translateY(100%);
    transition: .5s ease;
}

.portfolio-box:hover .portfolio-layer {
    transform: translateY(0);
}

.portfolio-layer h4 {
    font-size: 2rem;
}

.portfolio-layer p {
    font-size: 1rem;
    margin: .3rem 0 1rem;
}

.portfolio-layer a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background: var(--text-color);
    border-radius: 50%;
}

.portfolio-layer a i {
    font-size: 2rem;
    color: var(--second-bg-color);
}

.contact h2 {
    font-size: 3rem;
}

.contact form {
    max-width: 70rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact form .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact {
    background: var(--second-bg-color);
}

.contact form .input-box input,
.contact form textarea {
    width: 100%;
    padding: 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: var(--bg-color);
    border-radius: .8rem;
    margin: .7rem 0;
}

.contact form .input-box input {
    width: 49%;
}

.contact form textarea {
    resize: none;
}

.contact form .btn {
    margin-top: 1rem;
    cursor: pointer;
}
.role-selection {
    margin: 1rem 0;
    display: flex;
    gap: 2rem;
  }
  
  .role-selection label {
    font-size: 1.6rem;
    color: var(--text-color);
    cursor: pointer;
  }
  
  .role-selection input[type="radio"] {
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
.background-image {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 9%;
    font-size: 20px;
    margin-top: -15px;
    background-color: #1f242d;
}

.footer-iconTop {
    font-size: 60px;
    color: aqua;
}
/* @media (max-width: 768px) {
    
    .background-image {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
        justify-content: center;
        /* background-size: cover; 
        background-size: contain;
        background-position: center;
    }
   
} */
@media (max-width: 768px) {
    .background-image {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 50%;
        height: 50%;
        z-index: -1;
        justify-content: center;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .home {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10rem 1rem;
    }

    .home-content h1 {
        font-size: 2.5rem;
        line-height: 1.2;
    }

    .home-content p {
        font-size: 1.4rem;
    }

    .social-media a {
        width: 3rem;
        height: 3rem;
        font-size: 1.6rem;
        margin-top: 1rem;
    }
}


@media (max-width: 1200px) {
    html {
        font-size: 55%;
    }
}

@media (max-width: 991px) {
    .header {
        padding: 2rem 3%;
    }

    section {
        padding: 10rem 3% 2rem;
    }

    .footer {
        padding: 2rem 3%;
    }
}

@media (max-width: 768px) {
    #menu-icon {
        display: block;
    }

    .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 1rem 3%;
        background: var(--bg-color);
        border-top: .1rem solid rgba(0, 0, 0, .2);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
        display: none;
    }

    .navbar.active {
        display: block;
    }

    .navbar a {
        display: block;
        font-size: 2rem;
        margin: 2rem 0;
    }

    .portfolio-container {
        display: block;
        padding: 10px;
    }

    .portfolio-box {
        margin-top: 20px;
    }

    .about {
        flex-direction: column-reverse;
        font-size: 2rem;
        text-align: center;
    }

    .about img {
        width: 70vw;
        margin-top: 4rem;
    }

    .about-content .heading {
        text-align: center;
    }

    .contact form .input-box input {
        width: 100%;
    }

    .header {
        background: var(--bg-color);
    }
}
